<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <div v-if="products">
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0">
          <v-col cols="12">
            <h1>
              Complementos de
              <span class="font-weight-light">{{ getBusinessName }}</span>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="modalAddAddon = true"
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                Crear nuevo complemento
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="products"
              :search="$store.state.search"
              :items-per-page="15"
              :fixed-header="true"
              :loading="loading"
              :sort-by="['name']"
              :sort-desc="[false, true]"
              :single-expand="true"
              item-key="id"
              group-key="id"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.isAvailable`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isAvailable: item.isAvailable },
                      item,
                      'isAvailable'
                    )
                  "
                  v-model="item.isAvailable"
                ></v-switch>
              </template>

              <template v-slot:[`item.visible`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { visible: item.visible },
                      item,
                      'visible'
                    )
                  "
                  v-model="item.visible"
                ></v-switch>
              </template>

              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item,
                      'active'
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn
                  @click="editAddon(item)"
                  small
                  class="ma-2"
                  color="primary"
                >
                  Editar
                </v-btn>
                <v-btn
                  color="red darken-4"
                  class="white--text ma-2"
                  @click="
                    itemToDelete = item;
                    modalDelete = true;
                  "
                  small
                >
                  Eliminar
                </v-btn>
              </template>

              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div
                    style="
                      position: absolute;
                      z-index: 999;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">
            {{ itemToDelete.name }}
          </span>
          ?
        </v-card-text>

        <v-card-actions class="mt-2" style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="modalAddAddon" max-width="600px">
      <v-overlay class="loading-center" :value="save">
        <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay>
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">Nuevo complemento</span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="handleCreateCancel"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>Nombre del complemento</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el nombre"
                  v-model="addon.name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="addon.isAvailable"
                  label="Disponible en el app"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="addon.visible"
                  label="Visible en sidekick"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <div class="note">
                  <small>
                    *El precio se ingresa al momento que un complemento se
                    asigna como parte de la configuración de un producto
                  </small>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0 ma-0">
              <v-col cols="6">
                <v-row justify="start">
                  <v-switch
                    label="Visible en el app"
                    v-model="addon.active"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row justify="end">
                  <v-btn class="save-btn" color="primary" @click="newAddon">
                    Guardar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalEditAddon" max-width="600px">
      <v-overlay class="loading-center" :value="save">
        <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay>
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">Editar complemento</span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalEditAddon = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>Nombre del complemento</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el nombre"
                  v-model="addonToEdit.name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="addonToEdit.isAvailable"
                  label="Disponible en el app"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="addonToEdit.visible"
                  label="Visible en sidekick"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <div class="note">
                  <small>
                    *El precio se ingresa al momento que un complemento se
                    asigna como parte de la configuración de un producto
                  </small>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0 ma-0">
              <v-col cols="6">
                <v-row justify="start">
                  <v-switch
                    label="Visible en el app"
                    v-model="addonToEdit.active"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row justify="end">
                  <v-btn class="save-btn" color="primary" @click="updateAddon">
                    Guardar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "addon",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      item: 0,
      products: [],
      prevProducts: [],
      itemToDelete: null,
      modalDelete: false,
      modalEditAddons: false,
      searchableTags: [
        { name: "Activo", color: "lime darken-1" },
        { name: "Disponible", color: "green" },
        { name: "Addon", color: "amber" },
      ],
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Disponible en el app", value: "isAvailable" },
        { text: "Visible en sidekick", value: "visible" },
        { text: "Visible en el app", value: "active" },
        { text: "", value: "options", align: "end" },
      ],
      loading: true,
      addon: {
        active: true,
        addon: true,
        addonPrice: 0,
        addons: [],
        adults: false,
        barcode: "",
        business: [],
        calories: 0,
        deleted: false,
        description: "",
        disclaimer: "",
        discount: 0,
        expires: false,
        expiresOn: new Date(),
        createdAt: new Date(),
        createdBy: this.$store.state.user[".key"],
        featured: false,
        fractionable: false,
        height: 0,
        instructions: "",
        isAvailable: true,
        menu: [],
        minStock: 0,
        name: "",
        ochoActive: true,
        owner: "ocho",
        presentations: false,
        price: 0,
        rating: 0,
        schedule: [],
        sizeUnits: "",
        sold: 0,
        stock: 0,
        tags: [""],
        takeOutCost: "",
        tax: 0.15,
        timeToProcess: 0,
        url: "",
        images: [],
        userLimit: false,
        userMax: 0,
        visible: true,
        visits: 0,
        volume: 0,
      },
      snackbar: false,
      snackbarText: "",
      tagsToSearch: null,
      tagsToSearchSection: null,
      search: "",
      save: false,
      suscriptionProducts: null,
      modalAddAddon: false,
      modalEditAddon: false,
      addonToEdit: {},
    };
  },
  watch: {
    selectedBusiness() {
      this.products = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
  },
  methods: {
    handleCreateCancel() {
      this.modalAddAddon = false;
      this.addon.name = "";
      this.addon.active = true;
      this.addon.visible = true;
      this.addon.isAvailable = true;
    },

    editAddon(item) {
      this.addonToEdit = Object.assign({}, item);
      this.modalEditAddon = true;
    },
    updateAddon() {
      if (this.addonToEdit.name) {
        this.save = true;
        db.collection("products")
          .doc(this.addonToEdit.id)
          .update({
            name: this.addonToEdit.name,
            active: this.addonToEdit.active,
            visible: this.addonToEdit.visible,
            isAvailable: this.addonToEdit.isAvailable,
          })
          .then(() => {
            this.save = false;
            this.snackbarText = "Información del complemento actualizada.";
            this.snackbar = true;
            this.modalEditAddon = false;

            const prevValue = this.prevProducts.filter(
              (product) => product.id === this.addonToEdit.id
            );

            this.createLedger({
              source_id: this.addonToEdit.id,
              source_type: "products",
              currentValue: {
                name: this.addonToEdit.name,
                active: this.addonToEdit.active,
                visible: this.addonToEdit.visible,
                isAvailable: this.addonToEdit.isAvailable,
              },
              prevValue: {
                name: prevValue[0].name || "",
                active: prevValue[0].active || "",
                visible: prevValue[0].visible || "",
                isAvailable: prevValue[0].isAvailable || "",
              },
            });
          })
          .catch(() => {
            this.save = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el nombre del complemento";
        this.snackbar = true;
      }
    },
    confirmDelete: function () {
      let self = this;
      if (this.itemToDelete) {
        db.collection("products")
          .doc(this.itemToDelete.id)
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            self.modalDelete = false;
            self.snackbar = true;
            self.snackbarText = "Complemento eliminado";

            this.createLedger({
              source_id: this.itemToDelete.id,
              source_type: "products",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: new Date(),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: this.itemToDelete,
            });
          })
          .catch(() => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    newAddon() {
      this.addon.createdAt = new Date();
      this.addon.business = [this.selectedBusiness[".key"]];

      if (this.addon.name) {
        this.save = true;
        db.collection("products")
          .add(this.addon)
          .then((ref) => {
            this.save = false;
            this.snackbarText = "Complemento agregado correctamente.";
            this.snackbar = true;
            this.addon.name = "";
            this.modalAddAddon = false;

            this.createLedger({
              source_id: ref.id,
              source_type: "products",
              currentValue: this.addon,
              prevValue: {},
            });
          })
          .catch((err) => {
            this.save = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el nombre del complemento";
        this.snackbar = true;
      }
    },
    switchControlChanged(newValue, item, field) {
      this.save = true;
      let prevData = Object.assign({}, newValue);
      prevData[field] = !newValue[field];

      db.collection("products")
        .doc(item.id)
        .update(newValue)
        .then(() => {
          this.save = false;
          this.snackbarText = "Información del complemento actualizada.";
          this.snackbar = true;

          this.createLedger({
            source_id: item.id,
            source_type: "products",
            currentValue: newValue,
            prevValue: prevData,
          });
        })
        .catch(() => {
          if (newValue.active != undefined) item.active = !newValue.active;
          else if (newValue.vsible != undefined) item.vsible = !newValue.vsible;
          else if (newValue.isAvailable != undefined)
            item.isAvailable = !newValue.isAvailable;

          this.save = false;
          this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    getBusinessData() {
      this.loading = true;
      let self = this;
      this.$store.state.search = "";

      if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.suscriptionProducts = db
        .collection(`products`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("deleted", "==", false)
        .where("addon", "==", true)
        .onSnapshot(function (querySnapshot) {
          self.products = [];
          querySnapshot.forEach(function (doc) {
            let product = doc.data();
            product.id = doc.id;
            self.products.push(product);
            self.prevProducts.push(product);
          });

          self.loading = false;
        });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";

      let excelHeader = [
        {
          "Nombre del complemento": "",
          "Disponible en el app": "",
          "Visible en sidekick": "",
          "Visible en el app": "",
        },
      ];

      csvContent += [
        Object.keys(excelHeader[0]).join(";"),
        ...arrData.map(
          (item) =>
            `${item.name};${item.isAvailable ? "Si" : "No"};${
              item.visible ? "Si" : "No"
            };${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.getBusinessName}_complementos(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },
  },
  destroyed() {
    if (this.suscriptionProducts) this.suscriptionProducts();
  },
  mounted() {
    // document
    //   .getElementsByClassName("v-data-table__wrapper")[0]
    //   .addEventListener("scroll", function(e) {
    //     document.body.scrollTop = document.documentElement.scrollTop = document.getElementsByClassName(
    //       "v-data-table__wrapper"
    //     )[0].scrollTop;
    //   });

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "complementos";
    this.getBusinessData();
  },
};
</script>

<style scoped>
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.fa-edit {
  cursor: pointer;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.img-preview img {
  max-width: 100%;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.save-btn {
  margin-top: 20px;
}
.note {
  border: 1px solid #f0703d42;
  background-color: #8080801f;
  padding: 10px;
}
small {
  margin-left: 10px;
  width: 90%;
  margin-top: 30%;
}
</style>
